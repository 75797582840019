
/* eslint-disable */
import { clean } from "@/utils/object"
import moment from "moment"

/**
 @description: table to mixins
 @param {Promise} axios_get 
 @returns {Object} mixin
**/



export const mixin_list = (axios_get, config = {}) => {
  return {
    data() {
      return {
        nowRoute:'',
        list_data: {
          list: [],
          total: 0
        },
        page: 1,
        pageSize: 20,
        selete: [],
        ordering: "",
        ...config
      }
    },
    methods: {
      initRules () {
        const formInfo = this.formInfo
        formInfo.rules = this.$initRules(formInfo.fieldList)
      },
      outExe() {
        this.$confirm('시간이 조금 걸릴 수 있습니다.엑셀 출력하시겠습니까?', '알림', {
          confirmButtonText: '출력',
          cancelButtonText: '취소',
          type: 'warning'
        }).then(async () => {
          // this.excelData = await this.getAllList();//to excel (all data)
          this.export2Excel()
        }).catch(() => {

        });
      },
      outExeAll(type,api) {
        this.$confirm('시간이 조금 걸릴 수 있습니다.엑셀 출력하시겠습니까?', '알림', {
          confirmButtonText: '출력',
          cancelButtonText: '취소',
          type: 'warning'
        }).then(async () => {
          this.excelData = await this.getAllList(type,api?api:'');//to excel (all data)
          let arr = []
          if(type === 'memberList'){
            this.excelData.forEach((item)=>{
              var o={}
              o['ID'] = item.id
              o['undefined'] = ''
              o['가입일자'] = item.create_time
              o['아이디'] = item.username
              o['이름'] = item.real_name
              o['성별'] = item.gender === 0 ? '남자' : item.gender === 1 ? '여자' : item.gender === 2 ? '기타' : ''
              o['생일'] = item.birth
              o['휴대전화번호'] = item.phone
              o['주소'] = item.address + item.address_detail
              o['포인트'] = item.score
              o['계정 상태'] = item.active === 0 ? '탈퇴' : item.active === 1 ? '사용중' : ''
              arr.push(o)
            })
          }else if(type === 'orderList'){
            this.excelData.forEach((item)=>{
              var o={}
              o['ID'] = item.id
              o['undefined'] = ''
              // o['수강과목 '] = item.title
              o['아이디'] = item.user.username
              o['결제과목'] = item.subject.name
              // o['결제내역'] = item.order_id
              o['결제금액'] = item.price
              o['사용 포인트'] = item.score
              o['결제시간'] = item.paytime
              // o['공개여부'] = item.status === 1 ? '결제대기' : item.status === 2 ? '결제완료' : item.status === 3 ? '기한만료' : ''
              arr.push(o)
            })
          }else if(type === 'examPersonal'){
            this.excelData.forEach((item)=>{
              var o={}
              o['ID'] = item.id
              o['undefined'] = ''
              o['사용자 아이디'] = item.user.username
              // o['응시직렬'] = item.position.title
              o['수강과목'] = item.subject_info.title
              o['전체 진도'] = item.progress_rate
              o['정답율'] = item.correct_rate
              // o['섹션명'] = item.chapter.title
              // o['문제'] = item.exam.title
              // o['문제내용'] = this.getSumText(item.exam.content) + this.getSumImgSrc(item.exam.content)
              // o['정확여부'] = item.is_bool === 0 ? '틀림' : item.is_bool === 1 ? '맞음' : ''
              arr.push(o)
            })
          }else if(type === 'examGroup'){
            this.excelData.forEach((item)=>{
              var o={}
              o['ID'] = item.id
              o['undefined'] = ''
              o['아이디'] = item.user.username
              o['방이름'] = item.group.name
              o['문제'] = item.exam.title
              o['문제내용'] = this.getSumText(item.exam.content) + this.getSumImgSrc(item.exam.content)
              o['정확여부'] = item.is_bool === 0 ? '틀림' : item.is_bool === 1 ? '맞음' : ''
              arr.push(o)
            })
          }else if(type === 'groupList'){
            this.excelData.forEach((item)=>{
              var o={}
              o['ID'] = item.id
              o['undefined'] = ''
              o['등록시간'] = item.create_time
              o['업데이트 시간'] = item.update_time
              o['이름'] = item.user.username
              o['방이름'] = item.name
              o['과목명'] = item.subject.name
              o['섹션명'] = item.chapter.name
              o['시간'] = item.timer
              o['종류'] = item.type === 1 ? '랜덤방' : item.type === 2 ? '그룹방' : ''
              arr.push(o)
            })
          }else if(type === 'subjectList'){
            this.excelData.forEach((item)=>{
              var o={}
              o['ID'] = item.id
              o['undefined'] = ''
              o['등록시간'] = item.create_time
              o['과목 이름'] = item.title
              o['응시직렬 이름'] = item.position
              o['섹션'] = item.chapter
              o['문제'] = item.examination
              o['정렬순'] = item.order
              o['썸네일'] = item.cover
              o['공개여부'] = item.status === 0 ? '비공개' : item.status === 1 ? '공개' : ''
              o['홈화면'] = item.is_hot === 0 ? '보통' : item.is_hot === 1 ? '홈화면' : ''
              arr.push(o)
            })
          }
          this.export2Excel(arr,type)
        }).catch(() => {

        });
      },

      getSumText(str) {
        return str
          .replace(/<[^<>]+>/g, "")
          .replace(/&nbsp;/gi, "");
      },
      getSumImgSrc(richtext) {
        let imgList = [];
        richtext.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
              imgList.push(capture);
        });
        return imgList;
    },
      export2Excel(e, type) {
        var that = this;
        require.ensure([], () => {
          var key = []
          that.columns.forEach(item=>{
            if(item.label!=='操作'&&item.label!=='관리'){
              key.push(item.label)
            }
          })
          // real name
          var head = []
          let ignore  //record ignore columns
          let multiple
          Array.prototype.slice.call(document.getElementsByTagName('thead')[0].children[0].children).forEach((item,index)=>{
            if(item.innerText!=='操作'&&item.innerText!=='관리'&&item.innerText!==''){
              head.push(item.innerText)
            }else if(item.innerText==='操作'||item.innerText==='관리'){
              ignore = index
            }else if(item.innerText===''&&index===0){
              multiple = true
            }
          })
          // if(type === ''){
          //   //***************
          //   key = Object.keys(e[0])
          //   head = Object.keys(e[0])
          // }
          // key.pop()
          // head.pop()
          let arr1=[]
          Array.prototype.slice.call(document.getElementsByTagName('tbody')[0].children).forEach(item=>{
            var o={}
            Array.prototype.slice.call(item.children).forEach((k,index)=>{
              if(index!==ignore){
                if(multiple) index-=1
                o[head[index]]=k.innerText
                Array.prototype.slice.call(k.getElementsByTagName('img')).forEach((item)=>{
                  o[head[index]]+=item.currentSrc
                })
              } 
            })
            arr1.push(o)
          })
          if(e) arr1 = e
          // console.log(that.columns,head,key,arr1)
          const { export_json_to_excel } = require('../excel/Export2Excel');
          const tHeader = key; //  head name (pipei)
          const filterVal = head; //  real head name
          const list = arr1;
          const data = that.formatJson(filterVal, list);
          let time1
          // let time2
          if (this.start !== '') {
            time1 = moment(that.start).format('YYYY-MM-DD')
          }
          // if (this.end !== '') {
          //   time2 = moment(that.end).format('YYYY-MM-DD')
          // }
          let allList = this.$route.matched.filter((item) => {
            if (item.meta && item.meta.title) {
                return true;
            }
          });
          export_json_to_excel(tHeader, data, `[${time1}]${that.$t(allList[allList.length-1].meta.title)}`);// excel name
        })
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]))
      },
      allSelect(val) {
        this.selete = val;
      },
      sort_change({ prop, order }) { //ascending up id
        if (order === null) {
          this.ordering = ''
          return
        }
        this.ordering = order === "ascending" ? prop : `-${prop}`;
      },
      reset() {
        Object.keys(this.filter).forEach(key => {
          if(key !== 'subject' && key !== 'parent' && key !== 'section' && key!=='user' && key !=='id'){
            this.filter[key] = ''
          }
        })
        this.page = 1
        this.get_list()
      },
      async get_list() {
        const { pageSize, page } = this;
        const filter = this.filter
        const data = clean({
          page_size: pageSize,
          page: page,
          ...filter,
          ordering: this.ordering
        })
        const { results, count } = await axios_get(data)
        this.list_data = {
          list: results,
          total: count
        };
      },
      async getAllList(type,api){
        const filter = this.filter
        const data = clean({
          ...filter,
          ordering: this.ordering
        })
        if(!api){
          var {results} = await axios_get(data)
        }
        return new Promise((resolve)=>{
          resolve(results)
        })
      },
    },
    computed: {
      get_select() {
        return this.selete.map(x => x.id)
      }
    },
    watch: {
      page() {
        this.get_list();
      },
      pageSize() {
        this.get_list();
      },
      ordering() {
        this.get_list();
      },
    },
    created() {
      this.nowRoute = this.$route
      if(this.$store.state.paramsList.length > 0){
        let params = JSON.parse(this.$store.state.paramsList[this.$store.state.paramsList.length-1])
        if( this.$route.path === params.path){
          this.filter = params.filter
          this.pageSize = params.pageSize
          this.page = params.page
          this.ordering = params.ordering
          this.$store.commit('delParams')
        }
      }
      this.get_list();
    },
    
    beforeDestroy(){
      if(this.$store.state.paramsList.length>0){
        let params = JSON.parse(this.$store.state.paramsList[this.$store.state.paramsList.length-1])
        if(this.$route.path !== params.path){
          this.$store.commit('addParams',JSON.stringify({
            filter:this.filter,
            pageSize: this.pageSize,
            page: this.page,
            ordering: this.ordering,
            path:this.nowRoute.path
          }))
        }
      }else{
        this.$store.commit('addParams',JSON.stringify({
          filter:this.filter,
          pageSize: this.pageSize,
          page: this.page,
          ordering: this.ordering,
          path:this.nowRoute.path
        }))
      }
    }
  }
}